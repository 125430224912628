import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import BuildUnavailableLayout from './BuildUnavailableLayout'
import { useFindBuildIdByHostname } from './buildQueries'

export default function BuildUnavailable() {
  const router = useRouter()
  const {
    query: { domain, path = '/' }
  } = router
  const { data, loading } = useFindBuildIdByHostname(domain)
  const buildId = data && data.id

  useEffect(() => {
    if (buildId) {
      router.replace(`/revive-build/${buildId}?redirectToPath=${encodeURIComponent(path)}`)
    }
  }, [buildId])

  let text = 'Searching for your deployment...'
  let title
  let actionText
  let actionHref

  if (!loading && !buildId) {
    text = `Deployment "${domain}" was not found in our database.`
    title = 'Deployment not found'
    actionText = 'Open your Layer0 dashboard'
    actionHref = '/'
  }

  return (
    <BuildUnavailableLayout
      title={title}
      loading={loading}
      text={text}
      actionText={actionText}
      actionHref={actionHref}
    />
  )
}
